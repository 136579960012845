.lazy-load-image-background.none > img {
    opacity: 0;
}

.lazy-load-image-background.none.lazy-load-image-loaded > img {
    opacity: 1;
    transition: opacity .3s;
}

/* override element-styles*/
.lazy-load-image-background.none.bg-cover {
    background-size: cover !important;
}

.lazy-load-image-background.none.bg-center {
    background-position: center !important;
}